<template>
  <header id="topnav" class="defaultscroll sticky" v-bind:style="props.style" :class="{ 'bg-white': isWhiteNavbar === true }">
    <div class="container">
      <div class="row justify-content-between">
        <router-link class="logo col-4" to="/">
          <div><img v-bind:src="logo.dark" height="48" alt="Company's logo" /></div>
        </router-link>
          <div class="d-flex justify-content-end">
            <a :href="faq.pdf" target="_blank" class="btn btn-outline-primary mt-3 mx-3 mb-3">
              <a-icon
                type="question-circle" 
                style="vertical-align: text-top" 
              />
              Preguntas Frecuentes
            </a>
            <router-link class="mt-3" to="/">
              <img v-bind:src="logo.light" height="48" alt="Company's logo" />
            </router-link>
          </div>
      </div>

      <div class="menu-extras">
        <div class="menu-item">
          <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>


      <div id="navigation">
        <ul class="navigation-menu" :class="{ 'nav-light': navLight === true }">

          <li v-for="(option, idx) in menu"
            v-bind:key="idx"
            v-bind:class="{ 'has-submenu': option.hasSubMenu }">

            <template v-if="option.hasSubMenu">
              <a href="javascript:;">{{option.label}}</a>
              <span class="menu-arrow"></span>
              <ul class="submenu">
                <li v-for="(child, oidx) in option.children"
                  v-bind:key="oidx">
                  <router-link v-bind:to="child.path">{{child.label}}</router-link>
                </li>
              </ul>
            </template>
            <router-link v-bind:to="option.path" v-else>{{option.label}}</router-link>
          </li>
        </ul>
        <div class="menu-extras">
          <div class="menu-item">
            <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        isCondensed: false,
      };
    },
    props: {
      props: {
        type: Object,
        default: function() {
          return {};
        }
      },
      isWhiteNavbar: {
        type: Boolean,
      },
      navLight: {
        type: Boolean,
      },
      isIcons: {
        type: Boolean,
      },
    },
    computed: {
      authenticated() {
        return !!this.$store.state.auth.user;
      },
      ...mapGetters({
        logo: "system/logo",
        menu: "system/menu",
        faq: "system/faq",
      })
    },
    methods: {

      /*

      */

      /**
       * Toggle menu
       */
      toggleMenu() {
        this.isCondensed = !this.isCondensed;
        if (this.isCondensed) {
          document.getElementById("navigation").style.display = "block";
        } else document.getElementById("navigation").style.display = "none";
      },

      /**
       * Menu clicked show the submenu
       */
      onMenuClick(event) {
        event.preventDefault();
        const nextEl = event.target.nextSibling.nextSibling;

        if (nextEl && !nextEl.classList.contains("open")) {
          const parentEl = event.target.parentNode;
          if (parentEl) {
            parentEl.classList.remove("open");
          }
          nextEl.classList.add("open");
        } else if (nextEl) {
          nextEl.classList.remove("open");
        }
        return false;
      },
    },
    mounted: () => {
      window.onscroll = function () {
        onwindowScroll();
      };

      function onwindowScroll() {
        const topnav    = document.getElementById("topnav");
        const backToTop = document.getElementById("back-to-top");

        if (topnav) {
          if (
            document.body.scrollTop > 50 ||
            document.documentElement.scrollTop > 50
          ) {
            topnav.classList.add("nav-sticky");
          } else {
            topnav.classList.remove("nav-sticky");
          }
        }

        if (backToTop) {
          if (
            document.body.scrollTop > 100 ||
            document.documentElement.scrollTop > 100
          ) {
            backToTop.style.display = "inline";
          } else {
            backToTop.style.display = "none";
          }
        }
      }

      var links = document.getElementsByClassName("side-nav-link-ref");
      var matchingMenuItem = null;
      for (var i = 0; i < links.length; i++) {
        if (window.location.pathname === links[i].pathname) {
          matchingMenuItem = links[i];
          break;
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");
        var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
          parent.classList.add("active");
          const parent2 = parent.parentElement;
          if (parent2) {
            parent2.classList.add("active");
            const parent3 = parent2.parentElement;
            if (parent3) {
              parent3.classList.add("active");
              const parent4 = parent3.parentElement;
              if (parent4) {
                const parent5 = parent4.parentElement;
                parent5.classList.add("active");
              }
            }
          }
        }
      }
    },
  };
</script>